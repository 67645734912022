<template>
    <div class="terms full-width" v-if="translates && translates[langUrl]">
        <div
            class="terms__main-image">
            <div class="terms__main-image__opacity"></div>
            <div class="terms__main-image__container">
                <!-- <div v-if="!isDesktop" class="main-page__main-image__container__filter">
                    <v-text-field
                        label="Направление"
                    />
                </div> -->
                <div class="terms__main-image__container__text">Booking Terms</div>
                <!-- <div class="terms__main-image__container__comment">6 простых шагов!</div> -->
                <!-- <div v-if="pageTemplate.main.links" class="main-page__main-image__container__directions">
                    <template v-for="(link, index) in pageTemplate.main.links">
                        <div
                            :key="`main-links-${index}`"
                            class="terms__main-image__container__direction"
                            @click="$router.push(link.link)">{{ link.title }}</div>
                    </template>
                </div> -->
            </div>
        </div>
        <div class="terms__text__container">
            <div class="terms__text">
                <div class="terms__text__updated">Last updated Jan 09, 2023</div>
                <h2>Host Booking Terms</h2>

                <h3>GetAdventures.co</h3>
                <h3>Booking Agreement</h3> 
                <h3>Terms and Conditions</h3>

                <p>These Terms and Conditions (these “<span>Terms</span>”) govern each user’s (“<span>you</span>”, “<span>your</span>”, or “<span>user</span>”) access to and use of the site at <span>getadventures.co</span> (the “<span>Website</span>” or “<span>Company Website</span>”) which is owned and/or operated by Go-Peaks, Inc. a California corporation (“<span>Company</span>”, “<span>GetAdventures</span>”, “<span>we</span>”, “<span>us</span>”, “<span>our</span>”), and any other products, mobile applications, other apps or other platforms the Company offers (hereinafter, the <span>Website</span> and these other products, <span>mobile applications</span>, other <span>apps</span> and/or other platforms are collectively referred to as “<span>Company Programs</span>”).</p>
                <p>The term "you" “traveler” refers to the traveler making a booking with us and all travelers for whom the traveler makes reservations.</p>
                <p>PLEASE READ THE FOLLOWING TERMS CAREFULLY:</p>
                <p>THESE TERMS, TOGETHER WITH THE COMPANY’S PRIVACY POLICY (THE “PRIVACY POLICY” OR THE “COMPANY’S PRIVACY POLICY”), AND ANY OTHER TERMS OR CONDITIONS INCORPORATED BY REFERENCE INTO THESE TERMS (HEREINAFTER COLLECTIVELY REFERRED TO AS THIS “AGREEMENT”) FORM A LEGALLY BINDING CONTRACT BETWEEN YOU AND THE COMPANY. PLEASE READ ALL OF THE PROVISIONS OF THE AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION ABOUT YOUR ACCESS AND USE OF THE SITE OR ANY OTHER COMPANY PROGRAMS.</p>
                <p>BY CONTINUING TO USE OR OTHERWISE ACCESS THE SITE OR ANY OTHER COMPANY PROGRAM, YOU HEREBY AUTOMATICALLY AGREE TO BE BOUND BY ALL OF THE PROVISIONS OF THIS AGREEMENT (AS DEFINED ABOVE), AS PRESENTED TO YOU AS OF THE DATE OF YOUR FIRST USE OF EITHER THE SITE OR ANY OTHER COMPANY PROGRAM.</p>
                <p>AS SET FORTH IN MORE DETAIL IN SECTION 20 HEREIN, THE COMPANY RESERVES THE RIGHT, AT OUR DISCRETION AND AT ANY TIME, TO MAKE CHANGES TO ANY OF THE PROVISIONS OF THIS AGREEMENT, INCLUDING WITHOUT LIMITATION CHANGES TO ANY OF THE TERMS AND CONDITIONS GOVERNING A BOOKING OF A TRIP OR TO THE COMPANY PRIVACY POLICY (HEREINAFTER “UPDATES” TO THIS AGREEMENT). YOU WILL BE BOUND BY ALL UPDATES TO THIS AGREEMENT AS MORE FULLY SET FORTH IN SECTION 20 OF THIS AGREEMENT.</p>
                <p>NO CHANGES (ADDITIONS OR DELETIONS) BY YOU TO THIS AGREEMENT WILL BE ACCEPTED BY THE COMPANY. THUS, IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, THEN YOU SHOULD NOT ACCESS OR OTHERWISE USE THE SITE OR ANY OTHER COMPANY PROGRAMS.</p>
                <p>THIS AGREEMENT CONSTITUTES A BINDING CONTRACT BETWEEN YOU AND THE COMPANY AND YOU SHOULD DOWNLOAD AND PRINT THIS ENTIRE AGREEMENT (INCLUDING WITHOUT LIMITATION THESE TERMS AND THE COMPANY’S PRIVACY POLICY, AS DEFINED ABOVE) FOR YOUR RECORDS.</p>
                <p>PRIVACY NOTICE</p>
                <p>ALL USERS ARE DIRECTED TO SECTION 4 OF THIS AGREEMENT FOR A LINK TO THE COMPANY’S PRIVACY POLICY. WITHOUT LIMITING THE PROVISIONS OF SECTIONS HEREIN OR ANY OF THE PROVISIONS OF THE COMPANY’S PRIVACY POLICY, A USER’S USE OF ANY COMPANY PROGRAM, INCLUDING WITHOUT LIMITATION BOOKING ANY TRIP, CONSTITUTES YOUR AGREEMENT TO OUR COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION, INCLUDING THE USE OF COOKIES, PURSUANT TO THE COMPANY’S PRIVACY POLICY. PLEASE READ THE COMPANY’S PRIVACY POLICY (SEE SECTION 4 HEREIN) CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION ABOUT OUR COLLECTION, USE, STORAGE, DISCLOSURE, AND TRANSFER OF YOUR INFORMATION. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THE COMPANY’S PRIVACY POLICY, THEN YOU MAY NOT USE THE SITE OR ANY OTHER COMPANY PROGRAM.</p>
                
                <p><span>1. Definitions.</span> The following terms have the following meanings:</p>
                <p><span>1.1 “Access Right”</span> has the meaning set forth in Section 2 herein.</p>
                <p><span>1.2 “Booking(s)”</span> or <span>“Book a Trip”</span> has the meaning set forth in Section 5 herein.</p>
                <p><span>1.3 “Content”</span> means any data, information, messages, text, photos, graphics, videos, messages, tags, works, material or any other content, including, without limitation, any personal identifiable information.</p>
                <p><span>1.4 “Company IP Assets”</span> has the meaning set forth in Section 3.2 herein.</p>
                <p><span>1.5 “Company Privacy Policy”</span> has the meaning set forth in Section 4 herein.</p>
                <p><span>1.6 “Company Server”</span> or <span>“Server”</span> means the computer software or hardware that serves and hosts the Website or Company Programs to users across the Internet.</p>
                <p><span>1.7 “Host”</span> means the influencer, entrepreneur, or other business that hosts a Trip which Travelers can then Book by using the Company Website or other authorized Company Program.</p>
                <p><span>1.8 “Organizer”</span> has the meaning defined in Section 5 herein.</p>
                <p><span>1.9 “Trip Leader”</span> The local representative assigned by the “Operator” who is the authority on the trip organizing the logistics and overseeing the group.</p>
                <p><span>1.10 “Traveler”</span> means any individual user of the Company Website or other authorized Company Program who Books a Trip using such a Company Website or other Company Program.</p>
                <p><span>1.11 “Trip”</span> means the itinerary with identified host, dates, and price as more specifically described in the Company Website.</p>
                <p><span>1.12 “Trip Payments”</span> means the Trip payments that will be processed by Company as follows:</p>
                <p>1.12.1 The Trip payment that the Company will process on behalf of the Traveler who Books a Trip through the Trip Services, such payments to be processed and made by the Company on behalf of the Traveler to the third parties as described in, and otherwise pursuant to, the terms and conditions of the Bookings.</p>
                <p>1.12.2 The The Trip fees paid by a Host while using the Trip Services which will be transmitted through the Trip Services from the Host to a third party as agreed to by Company pursuant to said Trip Services.</p>
                <p>1.12.3 The Trip fees paid to an Operator while using the Trip Services which will be transmitted through the Trip Services from the Organizer to a third party as agreed to by Company pursuant to said Trip Services.</p>
                <p><span>1.13 “Trip Services”</span>means the following:</p>
                <p>1.13.1 For Travelers, it has the meaning set forth in Section 5 herein;</p>
                <p>1.13.2 For Hosts, it means the service offered to Hosts through the Website or other Company Program to assist the Host in coordinating certain logistics of a Trip for Travelers and Organizers; and/or</p>
                <p>1.13.3 For Operators, it means the service offered to Operators through the Website or other Company Program to assist the Operator in coordinating certain logistics of a Trip for Travelers and Hosts.</p>
                <p><span>1.14 “Trip Credit”</span>: voucher payment which must be applied towards a new trip within 365 days of issue.</p>
                <p><span>1.15 “Reschedule”</span> means a trip that has been postponed due to a Force Majeure event. The newly scheduled dates will be within 14 months of the previously planned departure.</p>
                <p><span>1.16 “Cancellation”</span> means any significant date alternation not caused by a Force Majeure event or the complete removal of a trip from sale and future operation by any party.</p>
                <p><span>1.17 “Cancellation/No Show Fee”</span> is a $1,500 USD fee owed to Company by the Host when the Host opts to cancel a confirmed departure for any reason at any time, unless otherwise granted an exemption by Company, due to a Covered Event or an act of Force Majeure.</p>
                <p>2. ACCESS AND CONDITIONS OF USE OF COMPANY WEBSITE AND ANY OTHER COMPANY PROGRAMS</p>
                <p>2.1 Subject to all of the terms and conditions of this Agreement, Company hereby grants to a user a non-exclusive, revocable, and limited right to access and use this Company Trip Website or Company Program, including without limitation any of the Trip Services provided through the Company Website or Company Program, in strict compliance with this Agreement (“Access Right”). Company reserves the right to suspend or revoke this Access Right at Company’s discretion without notice. Notwithstanding the foregoing Access Right, Company also has the right to change, suspend, or discontinue any (or all) aspects or features of this Company Website or Company Program or any of the Trip Services at any time, and from time to time, including the availability of any content or features on this Company Website or Company Program or any of the Trip Services. This Access Right granted to user under this Agreement will immediately terminate upon the expiration, cancellation or termination of this Agreement for any reason.</p>
                <p>2.2 User shall be responsible for obtaining and maintaining any equipment or ancillary services needed to connect to or access this Company Website or Company Program, including, without limitation, Internet connections, modems, hardware, software, and long distance or local telephone service.</p>
                <p>2.3 As a condition of each user’s use of this Company Website or Company Program, including without limitation any Trip Services, the user covenants to Company that: (a) user shall not use the Company Website or Company Program (including without limitation any Trip Services): (i) for any unlawful purpose or for any purpose that is prohibited by this Agreement; or (ii) in any manner that could damage, disable, overburden, or impair the Company Website or Company Program (including without limitation any Trip Services), or that would interfere with any other party's use and enjoyment of the Company Website or Company Program (including without limitation any Trip Services); and (b) user shall not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided through the Company Website or Company Program (including without limitation through any Trip Services).</p>
                <p>2.4 Notwithstanding any assistance that Company may provide, the user assumes sole responsibility for the uploading and updating of any of the user’s Content that is contained in the Company Website or Company Program.</p>
                <p>2.5. Users further agree as follows:</p>
                <p>2.5.1 Without limiting the generality of the foregoing, user agrees to all of the following provisions:</p>
                <p>(a) User will not upload to, distribute or otherwise publish through this Company Website or Company Program any Content that is unlawful, libelous, defamatory, invasive of privacy or publicity rights, harassing, threatening, abusive, inflammatory, obscene, or otherwise objectionable;</p>
                <p>(b) User will not upload or transmit any Content that would violate the rights of any party, would constitute or encourage a criminal offense, or would otherwise create liability or violate any local, state, federal or international law;</p>
                <p>(c) User will not upload or transmit any Content that may infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any party anywhere;</p>
                <p>(d) User will not impersonate any person or entity or otherwise misrepresent the user’s affiliation with a person or entity;</p>
                <p>(e) User will not distribute or publish unsolicited promotions, advertising, or solicitations for any goods, services or money, including junk mail and junk e-mail;</p>
                <p>(f) User will not use the Company Website or Company Program for purposes not authorized by Company; and</p>
                <p>(g) User will not use the Company Website or Company Program for any illegal purpose or any fraudulent scheme or transaction.</p>
                <p>2.5.2 In addition to the rights given to Company pursuant to this Agreement, user hereby grants Company a perpetual, worldwide, transferable, fully paid up right to use any of the user’s Content to:</p>
                <p>(a) provide the user with the Trip Services contemplated by this Company Website or Company Program, under this Agreement or under any other contract between user and Company, including without limitation any other uses normally intended for users;</p>
                <p>(b) to assist or coordinate with any claims arising out of the use of the Company Website or Company Program, including without limitation any claims involving any Travelers or Operators; and</p>
                <p>(c) for any other lawful purpose in carrying out Company business, operation or corporate purpose.</p>
                <p>2.6 Without limiting the generality of any other provisions herein, User agrees to all of the following provisions:</p>
                <p>2.6.1 Users are prohibited from violating or attempting to violate the security of this Company Website or Company Program or any Company Server (as defined herein), including, without limitation, (i) accessing data not intended for such user or logging into a server or account which the user is not authorized to access; (ii) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (iii) attempting to interfere with service to any user, host, or network; or (iv) sending unsolicited e-mail, including promotions and/or advertising of products or services.</p>
                <p>2.6.2 Any violations of any system or network security (including, but not limited to, that of this Company Website or Company Program or any Company Server may result in civil or criminal liability; and</p>
                <p>2.6.3 Company has the right to investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting Users who are involved in such violations. Company also reserves the right to cooperate with any and all law enforcement agencies, including complying with warrants, court orders and subpoenas and disclosing to law enforcement agencies any information about any User and anything a user does with respect to this Company Website or Company Program. By user’s use of the Company Website or Company Program, user hereby authorizes Company to take such action.</p>
                <p>2.7 If the user uploads, transmits, or otherwise contributes any Content (either owned by the User or otherwise) to the Company Website or Company Program, user hereby represents and warrants to Company that the user has the lawful right to distribute and reproduce such Content. Also, user is solely responsible for its conduct (and the conduct of its users) while using the Company Website or Company Program, including, but not limited to, all Content in any folders or web pages (if any), or through any other transactions or interactions user generates, transmits, or maintains via the Company Website or Company Program. Company takes no responsibility for any such online distribution or publication by user or by any other party. Company cannot and will not review every message or other Content that User or any other party may generate or post, and Company is not responsible for the Content thereof.</p>
                <p>2.8 In addition to any other right to terminate this Agreement, Company hereby has the absolute right to immediately terminate, without warning, any account that it believes, in its sole discretion, breaches any of the provisions of this Section.</p>
                <p>3. COPYRIGHTS, TRADEMARKS, AND OTHER INTELLECTUAL PROPERTY RIGHTS; RESERVATION OF RIGHTS.</p>
                <p>3.1 It is the Company’s policy is to respect the copyright, trademarks, and intellectual property rights of others. Company has the absolute right to (i) immediately terminate, without warning, all rights (including, without limitation, all Access Rights and any rights of any user who (in the Company’s determination) appear to infringe upon the copyright, trademarks, or intellectual property rights of others, and/or (ii) remove any such Content from a user from the Company Website or Company Program or used in any Trip Service that, in Company’s determination, may infringe the copyright, trademarks, or other intellectual property rights of any third party.</p>
                <p>3.2 Each user agrees to the following:</p>
                <p>3.2.1 Company or its licensors own all rights, title and interest, in the U.S. and elsewhere, in and to all trademarks, service marks (whether registered or common law marks), logos, and any other trade names displayed on or in the Company Websites and all other Company Programs (including without limitation the Company’s Trip Services) or otherwise embodied in or associated with any and all of the Company’s other products and services (hereinafter collectively referred to as “Trademarks/Trade Dress”). Users shall not use any Trademarks/Trade Dress in connection with any product or service that is not owned by Company, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits the Company. All other trademarks not owned by the Company which may appear in the Company Websites or any other Company Programs are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Company.</p>
                <p>3.2.2 All content included on or made available through any Company Websites or any other Company Programs, including without limitation any text, graphics, logos, page headers, button icons, scripts, images, audio clips, digital downloads, and data compilations, and all copyrights contained or arising out of the foregoing, is and shall remain the sole property of Company and is protected by United States and international copyright laws (collectively, “Works”).</p>
                <p>3.2.3 Without limiting the provisions of Section 3.2.1 or 3.2.2 above, user hereby acknowledges and agrees that Company or its licensors are the sole owners, worldwide, of all Trademarks/Trade Dress (as defined in Section 3.1.1 above), all Works (as defined in Section 3.1.2 above), and any and all other inventions, patents, logos, images, graphics, photos, videos, icons, content, features, functionalities, data, processes, techniques, software, website designs, copyrights, works, and all other intellectual property provided in, made available by using, or otherwise contained or embodied in, all Company Websites and all other Company Program, or otherwise used by Company in the furtherance of its Trip Services or any of its other products, services or overall business (collectively “Company IP Assets”).</p>
                <p>3.3 Company IP Assets are protected by the copyright, patent, trademark, and/or other intellectual property laws of both the United States and other countries. This Agreement shall not be interpreted to: (i) grant any rights to any user in or to any of the Company IP Assets except for the limited Access Right set forth and subject to the terms and conditions of Section 2.1 of this Agreement; or (ii) transfer any rights in any Company IP Assets or in any other Company intellectual property rights from Company to any users.</p>
                <p>3.4 Company IP Assets may not be used by user without the prior written permission from the Company. Any rights not expressly granted herein to User are reserved to Company. In addition to any other conditions on the user’s Access Right as set forth in this Agreement, the user’s Access Right is subject to the following additional conditions: (i) user shall not modify, disassemble, decompile or reverse translate or create derivative works from any of the Company IP Assets or otherwise attempt to derive any source code of the same or let any third party do the same; (ii) no copyrighted material, content, or any other Company IP Assets may be downloaded, modified, copied, displayed, transferred, distributed, sold, published, broadcast or otherwise used except as expressly stated either in such materials or in this notice without the express prior written permission of the Company (which the Company may or may not grant in its sole discretion); (iii) user shall not remove, alter, cover or obscure any copyright notices or other proprietary rights notices of Company or any other party placed on or embedded in the Company IP Assets and shall otherwise retain all such notices on all copies of the same; and (iv) use of any of the Company IP Assets is prohibited unless user is an authorized User in good standing. Unauthorized use is a violation of copyright and other intellectual property rights and is actionable under law.</p>
                <p>3.5 User agrees to keep strictly confidential all Company IP Assets that have not been made publicly available by Company. User also acknowledges and agrees that the terms and conditions of this provision shall survive the cancellation, expiration or termination of this Agreement for any reason.</p>
                <p>4. PRIVACY.</p>
                <p>4.1 User agrees that: (i) if the user has any User Content or any User login or password that it uses in association with the Company Website or Company Program or is otherwise used in association with any Trip Service, then User is solely responsible for maintaining the confidentiality of the same; and (ii) if the user has any login or password associated with the Company Website or Company Program or that is otherwise used in association with any Trip Service, then the user: (a) is solely responsible for all uses of its login and password regardless of whether these uses are authorized by the user, and (b) the user will immediately notify Company of any unauthorized use of the User’s login and password.</p>
                <p>4.2 Each user’s access to and use of the Company Website or any other Company Programs, including without limitation using any of the Trip Services, is subject to the Company’s Privacy Notice, a copy of which is available here https://getadventures.co/privacy-policy and which is hereby incorporated by reference into this Agreement (“Company Privacy Policy”). IN ADDITION TO ANY OTHER APPLICATIONS OR USES OF PERSONAL INFORMATION AS SET FORTH IN THE COMPANY PRIVACY POLICY, THE COLLECTION, USE, DISCLOSURE AND OTHER PROCESSING OF A USER’S PERSONAL INFORMATION IS GOVERNED BY THE COMPANY PRIVACY POLICY. As a result of such incorporation, each User hereby agrees to comply with the Company Privacy Policy, as it may be amended from time to time by the Company.</p>
                <p>RESCHEDULING AND CANCELLATION.</p>
                <p>5.1 Covered Event. For purposes of this Section, a “Covered Event” means you experience an injury, illness, or other medical condition that reasonably prevents you from performing the Host Services on the schedule described in the Trip, as diagnosed by a licensed medical professional. Written notice must be provided to the Company within 48 hours of diagnosis and be accompanied by documentation from the diagnosing medical professional.</p>
                <p>5.2 Rescheduling for Covered Event. Upon notice to the Company of a Covered Event prior to the Trip departure date, you may (a) Reschedule the Trip, without fee or penalty, provided the rescheduled date is be approved by the Company; or (b) cancel the Trip, provided that, You pay to the Company all funds or fees received by you related to the Trip within 30 days of notice of the Covered Event.</p>
                <p>5.3 Cancellation by You. Except as provided above, if you cancel a Trip at any time and for any reason, or fail to arrive at a destination for the Trip, you shall pay to the Company all funds or fees received by you related to the Trip. If you cancel a confirmed departure for a Trip, at any time, unless granted an exemption due to a Covered Event or an act of Force Majeure, you will be subject to and agree to pay a Cancellation/No Show Fee of $1,500 USD for each such cancellation. You shall pay such amount within 30 days of notice by the Company of the amount due. The Company may deduct such amounts from any funds due and owing to you under any other agreement. You agree and acknowledge that unfavorable exchange rate fluctuations that may lead to a greater amount due to the Company under this paragraph.</p>
                <p>5.4 Cancellation of individual passengers by You. If you choose to cancel individual confirmed travelers at any time and for any reason, other than in the event of a Qualifying Exemption (defined below), you shall pay to the Company the value of the total service fees for that spot if the spot is not filled prior to 90 days before a Trip's planned departure. A Qualifying Exemption means that you have security concerns and/or observe threatening behavior exhibited by the traveler/s that may impact the dynamic and safety of the Trip, travelers or yourself. The determination of a Qualifying Exemption is at the discretion of the Company. Any service fees shall be paid within 30 days of notice by the Company of the amount due. The Company may deduct such amounts from any funds due and owing to you under any other agreement. You agree and acknowledge that unfavorable exchange rate fluctuations that may lead to a greater amount due to the Company under this paragraph.</p>
                <p>5.5 Cancellation by the Company Due to Force Majeure Event. The determination of a Force Majeure event, and the subsequent operational amendments to The Trip will be at the discretion of the Operator and/or Company. Notwithstanding the above, the Company shall not be liable or responsible to you, nor be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement, when and to the extent such failure or delay is caused by or results from acts beyond the Company's reasonable control, including, without limitation, the following force majeure events ("Force Majeure Event(s)"): (a) acts of God; (b) flood, fire, earthquake, pandemics, epidemics, drought, bushfire, storm or other natural disaster, explosion, or public health and safety emergency characterized by an applicable government authority; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or actions; (e) embargoes or blockades in effect on or after the date of this Agreement; (f) national or regional emergency; (g) strikes, labor stoppages or slowdowns, or other industrial disturbances; (h) shortage of adequate power or transportation facilities; (i) other events beyond the reasonable control of the Company. In case of a Force Majeure Event, the Company shall provide you written notice of the Force Majeure Event, and provide notice of whether the Company, in its reasonable discretion elects to cancel the Trip or to Reschedule the Trip due to the Force Majeure Event.</p>
                <p>5.6 Health and Fitness: It is your sole responsibility to ensure that you are suitably fit to allow full participation in the trip. You agree to follow all proper and detailed medical advice for the latest health requirements and recommendations of their Trip destination including all vaccination requirements enforced by the country of operation. In the event you fail to adhere to the latest health requirements of the Trip's destination, which results in the cancellation of the trip, you will owe the Company all funds or fees received by you related to the Trip, and for any Travelers that cancel and are refunded for their Trip, an amount equal to the total service fees that the Company would have earned upon completion of the Trip. You shall pay such amount within 30 days of notice by the Company of the amount due. The Company may deduct such amounts from any funds due and owing to you under any other agreement.</p>
                <p>6. NON-DISPARAGEMENT. Host agrees that during and after the term of this Agreement, they will not knowingly vilify, disparage, slander or defame the Company, its officers, directors, employees, business or business practices.</p>
                <p>7. CONFIDENTIALITY.</p>
                <p>7.1 Definition. “Confidential Information” means any non-public information that relates to the actual or anticipated business, research, or development of Company and any proprietary information, trade secrets, and know-how of Company that is disclosed to Host by Company, directly or indirectly, whether in written, printed, oral, electronic or other form. Confidential Information includes, but is not limited to, the Website, research, product plans, products, services, customer lists, development plans, Work Product, processes, formulas, technology, methodologies, designs, drawings, marketing, compensation, finances, and other business information. Confidential Information is the sole property of Company.</p>
                <p>7.2 Exceptions. Confidential Information does not include any information that: (a) was publicly known and made generally available in the public domain prior to the time Company disclosed the information to Host, (b) became publicly known and made generally available, after disclosure to Host by Company, through no wrongful action or inaction of Host, or (c) was in Host’s possession, without confidentiality restrictions, at the time of disclosure by Company, as shown by Host’s files and records.</p>
                <p>7.3 Legally Compelled Disclosure. Nothing herein shall be construed to prevent disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to the valid order of a court of competent jurisdiction or an authorized government agency, provided that the disclosure does not exceed the extent of disclosure required by such law, regulation or order. Host shall provide written notice of any such order to an authorized officer of Company within three (3) days of receiving such order, but in any event sufficiently in advance of making any disclosure to permit Company to contest the order or seek confidentiality protections, as determined in Company’s sole discretion.</p>
                <p>7.4 Non-Disclosure and Non-Use. Host shall hold in trust and confidence the Confidential Information. Host shall not, without the prior written consent of Company, disclose, in whole or in part, the Confidential Information to any third party or use the Confidential Information for any purpose other than the performance of the Host Services. Host shall take all reasonable precautions to prevent any unauthorized disclosure of the Confidential Information including, but not limited to, having each employee of Host, if any, with access to any Confidential Information, execute a nondisclosure agreement containing terms that are substantially similar to the terms contained in these Terms. Any Confidential Information that Host develops in connection with the Host Services shall be subject to the terms and conditions of this clause. Host shall notify Company immediately in the event Host becomes aware of any loss or disclosure of any Confidential Information. Host must protect the Confidential Information against unauthorized use or disclosure with at least the same degree of care as the Host normally exercises to protect its own information of like character and importance, but in no event less than reasonable care or such higher standard of care as is justified by the facts and circumstances of the disclosure.</p>
                <p>7.5 Termination. Upon termination of this Agreement, or earlier upon Company's request, Host shall deliver all Items containing any Confidential Information to Company or make such other disposition thereof as Company may direct.</p>
                <p>7.6 Former Client Confidential Information. Host shall not, without prior written consent of Company and third party, use or disclose any confidential, proprietary information, trade secrets or know-how of any former or concurrent client of Host or other person or entity for any purpose other than the performance of Host Services. Furthermore, Host shall not bring onto the premises of Company or Company vendor any unpublished document or proprietary information belonging to any client, person, or entity unless consented to in writing by the client, person, or entity. Host shall indemnify, defend and hold harmless Company from and against all claims, liabilities, damages or expenses in any form whatsoever, including reasonable attorneys’ fees and costs, arising out of or in connection with any violation or claimed violation of Host’s duty to maintain the confidence of the third party’s information in accordance with these Terms.</p>
                <p>7.7 Third Party Confidential Information. Company has received, and in the future will receive, from third parties confidential or proprietary information subject to a duty on Company’s part to maintain the confidentiality of the information and to use it only for certain limited purposes. Host shall not to disclose such third party information, in whole or in part, to any person or entity, or use the third-party information except as necessary in carrying out the Host Services for Company consistent with Company’s agreements with these third parties.</p>
                <p>8. INTELLECTUAL PROPERTY RIGHTS.</p>
                <p>8.1 Host Content. Host shall be the sole and exclusive owner of all right, title and interest throughout the world in and to all photographs and video content, Literary Works, and Musical Works produced in relation to the Host Services (“Host Content”). Host hereby grants Company a limited, nonexclusive, royalty free, license to use, publish, reproduce, perform, display, distribute copies of, prepare derivative works based upon, make, have made, sell, offer to sell, and otherwise exploit such Host Content and derivative works thereof.</p>
                <p>8.2 Assignment. Except for Host Content and Literary and Musical Works, Company is and will be the sole and exclusive owner of all right, title and interest throughout the world in and to all the results and proceeds of the use of the Website and Company Programs under these Terms and all other writings, technology, work product, discoveries, processes, techniques, methods, ideas, concepts, research, proposals, and materials and all other work product of any nature whatsoever, that are created, prepared, produced, authored, edited, modified, conceived, or reduced to practice in the course of performing the Host Services or other work performed in connection with the Host Services or these Terms (collectively, the “Work Product”) including all patents, copyrights, trademarks (together with the goodwill symbolized thereby), trade secrets, know-how, and other confidential or proprietary information, and other intellectual property rights (collectively, “Intellectual Property Rights”) therein. Host agrees, except for Host Content and Literary and Musical Works, that the Work Product is hereby deemed “work made for hire" as defined in 17 U.S.C § 101 for Company and all copyrights therein automatically and immediately vest in Company. If, for any reason, Work Product does not constitute “work made for hire,” Host irrevocably assigns to Company, for no additional consideration, Host’s entire right, title and interest throughout the world in and to such Work Product, including all Intellectual Property Rights therein, including the right to sue for past, present and future infringement, misappropriation or dilution thereof.</p>
                <p>8.3 Further Assurances. Upon the request of Company, Host shall assist Company and its designees in every proper way to secure Company’s rights in the Work Product and related Intellectual Property Rights in all countries. Host shall disclose to Company all pertinent information and data with respect to Work Product and related Intellectual Property Rights. Host shall execute all applications, specifications, oaths, assignments, and other instruments that Company deems necessary in order to apply for and obtain these rights and in order to assign and convey to Company, its successors, assigns, and nominees the sole and exclusive right, title, and interest in and to the Work Product, and any related Intellectual Property Rights.</p>
                <p>8.4 Pre Existing Materials. Host remains the sole and exclusive owner of all of Host’s Literary and Musical Works (defined below). Host hereby grants Company a limited, non-exclusive, royalty free, license to include limited references to the Literary and Musical Works of the Host in order to promote, advertise, and summarize the Trip and its inclusion of the Literary and Musical Works to the nature of the Trip. “Literary and Musical Works” means copyrighted “literary works” and “musical works” as defined by the United States Copyright Act of 1976, as amended. Host may revoke such limited license with 20 days advance written notice.</p>
                <p>8.5 Attorney in Fact. If Host’s unavailability or any other factor prevents Company from pursuing or applying for any application for any United States or foreign registrations or applications covering the Work Product and related Intellectual Property Rights assigned to Company, then Host irrevocably designates and appoints Company as Host’s agent and attorney in fact. Accordingly, Company may act for and on Host’s behalf to execute and file any applications and to take all other lawfully permitted acts to further the prosecution and issuance of the registrations and applications with the same legal force and effect as if executed by Host.</p>
                <p>8.6 Photos and Marketing. You consent to the Company using images of you taken during the Trip for advertising and promotional purposes in any medium. Without any limitation to Company’s rights to any intellectual property as provided herein, you grant us a perpetual, royalty-free, worldwide, irrevocable license to use such images for publicity and promotional purposes.</p>
                <p>9. HOSTS REPRESENTATIONS AND WARRANTIES. Host represents and warrants to Company as follows:</p>
                <p>9.1 Organization Representations; Enforceability. If Host is a company, (a) Host is duly organized, validly existing, and in good standing in the jurisdiction stated in the preamble to these Terms, (b) the execution and delivery of these Terms by Host and the transactions contemplated hereby have been duly and validly authorized by all necessary action on the part of Host, and (c) these Terms constitutes a valid and binding obligation of Host that is enforceable in accordance with its terms.</p>
                <p>9.2 Compliance with Company Policies. Host shall perform the Host Services in accordance with all policies and procedures provided by Company, as may be amended from time to time by the Company only, including any third-party policies and procedures that Company is required to comply with.</p>
                <p>9.3 No Conflict. The entering into and performance of these Terms by Host does not and will not: (a) violate, conflict with, or result in a material default under any other contract, agreement, indenture, decree, judgment, undertaking, conveyance, lien, or encumbrance to which Host is a party or by which it or any of Host’s property is or may become subject or bound, or (b) violate any applicable law or government regulation. Host will not grant any rights under any future agreement, nor will it permit or suffer any lien, obligation, or encumbrances that will conflict with the full enjoyment by Company of its rights under these Terms.</p>
                <p>9.4 Non-Infringement. Nothing contained in the Work Product does or will infringe, violate, or misappropriate any Intellectual Property Rights of any third party. Further, no characteristic of any Work Product does or will cause, using, maintaining, or selling the Work Product to infringe, violate, or misappropriate the Intellectual Property Rights of any third party.</p>
                <p>9.5 No Pending or Current Litigation. Host is not involved in litigation, arbitration, or any other claim and knows of no pending litigation, arbitration, other claim, or fact that may be the basis of any claim regarding any of the materials Host has used or will use to develop or has incorporated or will incorporate into the Work Product to be delivered under these Terms.</p>
                <p>9.6 Services. Host has the required skill, experience and qualification to perform the Host Services and shall perform the Services in a professional and workmanlike manner in accordance with the generally recognized industry standards for similar services. Host shall devote sufficient resources to ensure that the Host Services are performed in a timely and reliable manner. Failure to provide the Host Services as agreed upon by the Host and Company for any or no reason including termination of the Host will result in the Host returning all paid earnings, including but not limited to, any flight advance or any additional applicable compensation paid to the Host by the Company. The Host will be obligated to return the requested funds within 30 days of written notice.</p>
                <p>9.7 Employees and Approved Subcontractors. Approved Subcontractors and any persons employed by Host in connection with the performance of Host under these Terms shall be the employees or contractors of Host and Host shall be fully responsible for such employee’s or contractor’s compliance with these Terms.</p>
                <p>9.8 COVID-19 Vaccine Requirements. Hosts have the option of requiring COVID-19 vaccination on Trips as defined by the relevant national authority. The Company will facilitate the communication of this requirement to Travelers via website copy and any other associated marketing collateral produced by the Company. Host is solely responsible to implement, administer, and enforce such vaccination requirement. The Host vaccination requirement is in addition to the vaccination and/or testing requirements enforced by the local authorities within the destination of travel. The Company will not facilitate the collection of proof in regards to vaccination status and is not responsible for providing or verifying vaccination status or other information. Host agrees and acknowledges that the Company disclaims all liability related to such vaccine requirements and the implementation and enforcement of such requirements.</p>
                <p>10. COMPANY’S REPRESENTATIONS AND WARRANTIES. Company represents, warrants and covenants to Host that (a) Company is duly organized, validly existing, and in good standing in the jurisdiction stated in the preamble to these Terms, (b) the execution and delivery of these Terms by Company and the transactions contemplated hereby have been duly and validly authorized by all necessary action on the part of Company, and (c) these Terms constitutes a valid and binding obligation of Company that is enforceable in accordance with its terms.</p>
                <p>11. INDEMNIFICATION & INSURANCE.</p>
                <p>11.1 Indemnification. Host shall indemnify, defend, and hold harmless Company, its affiliates and their directors, officers, employees, agents, licensors, content providers, successors and assigns from and against all claims, liabilities, damages, judgements, awards, taxes, losses, costs or expenses of any kind whatsoever, including attorneys’ fees and other legal expenses, that are incurred by Company arising out of or resulting from: (a) any unauthorized use of the Company Website, any Company Programs, and any Company IP Assets or any content thereof; (b) any actions of a Traveler during a Trip; (c) any negligent, reckless, or intentionally wrongful act of Host or Host’s assistants, employees, or agents, (d) any breach by Host or Host’s assistants, employees, or agents of any of the covenants, warranties, or representations contained in these Terms, (e) any failure of Host to perform the Host Services in accordance with all applicable laws, rules, and regulations, or (f) any violation or claimed violation of a third party’s rights resulting in whole or in part from Company’s use of the Work Product of Host under these Terms. Company may satisfy such indemnity (in whole or in part) by way of deduction from any payment due to Host.</p>
                <p>12. DISCLAIMERS; LIMITATION OF LIABILITY.</p>
                <p>EXCEPT FOR THE COMPANY’S LIMITED AND EXPLICIT PROMISE UNDER SECTION 6 TO PROCESS PAYMENTS: (A) THE COMPANY MAKES NO REPRESENTATIONS, WARRANTIES OR OTHER COVENANTS TO THE HOST OR ANY OTHER USER REGARDING THE COMPANY WEBSITE, ANY OTHER COMPANY PROGRAMS, ANY TRIP, ANY TRIP SERVICES, ANY OTHER COMPANY IP ASSETS, AND ANY OTHER CONTENT PROVIDED THEREIN; AND (B) THE COMPANY WEBSITE, ANY OTHER COMPANY PROGRAMS, ANY BOOKINGS, TRIP SERVICES, ANY COMPANY IP ASSETS AND ANY OTHER CONTENT PROVIDED THEREIN ARE ALL PROVIDED TO A HOST AND ALL OTHER USERS ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND.</p>
                <p>THE COMPANY HEREBY EXPLICITLY DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY: (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</p>
                <p>THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY PRODUCTS OR SERVICES OF ANY HOST, OPERATORS OR ANY OTHER THIRD PARTY, INCLUDING WITHOUT LIMITATION ANY OF THE FOLLOWING WITH REGARD TO THE FOREGOING: (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; (C) WARRANTY OF TITLE; OR (D) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</p>
                <p>FOR PURPOSES OF THIS SECTION 16, THESE THIRD PARTY PRODUCTS AND SERVICES INCLUDE PRODUCTS OR SERVICES OF HOSTS OR OPERATORS. WITHOUT LIMITATION THE GENERALITY OF ANY OTHER PROVISION IN THIS SECTION 16, AND FOR THE AVOIDANCE OF DOUBT, EACH HOST HEREBY ACKNOWLEDGES AND AGREES THAT: (A) THE SOLE AND EXCLUSIVE RESPONSIBILITY AND LIABILITY OF THE COMPANY RELATED TO OR ARISING OUT OF THE TRIP SERVICES SHALL BE LIMITED TO THE COMPANY’S PROCESSING OF THE TRIP PAYMENTS TO THE APPROPRIATE THIRD PARTY PURSUANT TO THE TERMS AND CONDITIONS OF THE PARTICULAR TRIP; AND (B) ALL OTHER SERVICES RELATED TO OR ARISING OUT OF THE TRIP, AND ALL OBLIGATIONS RELATED THERETO, ARE SOLELY AND EXCLUSIVELY THE RESPONSIBILITY AND LIABILITY OF THE OPERATOR, HOST OR OTHER THIRD PARTIES PROVIDING SUCH SERVICE AND ARE NOT THE RESPONSIBILITY OR LIABILITY OF THE COMPANY. IN NO EVENT SHALL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INJURY, PERSONAL INJURY, DEATH, PROPERTY DAMAGE, OR OTHER CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</p>
                <p>IN NO EVENT SHALL THE COMPANY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO THE HOST FOR THE TRIP.</p>
                <p>13. LINKS TO THIRD-PARTY WEBSITES. The Company Website, other Company Programs or our Trip Services may contain links to third-party websites. These links are provided for your convenience only. The Company has no control over third-party websites and we are not responsible for the content of such websites or the privacy practices of those third-party websites. If you decide to access a third-party website linked from the Company Website, other Company Programs or our Trip Services, you do so entirely at your own risk and your use of those sites is subject to those websites’ terms and conditions and privacy policies.</p>
                <p>14. GOVERNING LAW; JURISDICTION, DISPUTES. READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND IMPACTS HOW CLAIMS YOU AND COMPANY MAY HAVE AGAINST EACH OTHER ARE DETERMINED.</p>
                <p>14.1 Governing Law. We control and operate the Company Website, our Company Programs and our Trip Services from the State of Oregon, United States of America. While we invite users from all parts of the world to visit the Company Website and other Company Programs and to use our Trip Services, each user hereby acknowledges and agrees that the Company Website, other Company Programs and our Trip Services, and all activities available on and through the foregoing, are governed by the laws of the United States of America and the laws of the State of Oregon. Accordingly, you hereby expressly agree that the laws of the State of Oregon, excluding its conflict of laws rules shall govern this Agreement, including without limitation our Company Privacy Policy and your use of the Company Website, any other Company Programs and our Trip Services.</p>
                <p>14.2 Jurisdiction. You hereby expressly agree that exclusive jurisdiction for any claim or dispute with us (or any of our affiliates) related to or arising out of this Agreement shall be the federal and/or state courts in the State of Oregon, USA, and you further agree and expressly consent to the exercise of personal jurisdiction the federal and state courts in the State of Oregon, in connection with any such dispute and including any claim involving us or our affiliates, subsidiaries, employees, Travelers, officers, directors, or agents.</p>
                <p>14.3 Prohibition of Class or Representative Actions. You and the Company agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action.</p>
                <p>15. CHANGES TO THIS AGREEMENT.</p>
                <p>BY USING THE COMPANY WEBSITE, ANY COMPANY PROGRAM OR ANY TRIP SERVICES, YOU HEREBY AGREE AS FOLLOWS:</p>
                <p>15.1 The company reserves the right, at our discretion and at any time, to make changes to any of the provisions of this agreement, including without limitation changes to any of the terms and conditions governing a booking of a trip or to the company privacy policy (hereinafter “Updates to this Agreement”).</p>
                <p>15.2 The Company may take any reasonable efforts to post any such Updates to this Agreement, including without limitation posting such Updates to this Agreement on the Company’s main Website.</p>
                <p>15.3 Continued use of any Company Website, any other Company Program, or any of the Company’s Trip Services by any user (including but not limited to any traveler) shall automatically constitute such user’s (including without limitation such Traveler’s) immediate acceptance of and consent to all of the updates to this agreement and you will thereafter be bound by such Updates to this Agreement.</p>
                <p>15.4 If you do not agree to such Updates to this Agreement, then you should immediately discontinue using the Company Website, all other Company Programs, and all of the Company’s Trip Services.</p>
                <p>16. MISCELLANEOUS PROVISIONS.</p>
                <p>16.1 Entire Agreement; Waiver; Severability. This Agreement constitute the entire agreement between Company and each user (including without limitation each Traveler) with respect to the subject matter hereof, and supersedes and replaces any prior agreements we might have had between us regarding such subject matter. The Company’s failure to enforce any right or provision of this Agreement will not be considered a waiver of those rights. If any provision of this Agreement is held to be invalid or unenforceable by a court, the remaining provisions of this Agreement will remain in effect.</p>
                <p>16.2 Remedies. User acknowledges that monetary damages may not be a sufficient remedy for unauthorized use of the Company Website, any other Company Program, any Trip Services or any other Company IP Assets and therefore each user hereby agrees that the Company shall be entitled, without waiving any other rights or remedies, to such injunctive or equitable relief as may be deemed proper by a court or arbitration panel of competent jurisdiction without necessity of posting a bond and without having to plead and prove lack of an adequate remedy at law.</p>
                <p>16.3 Attorney Fees. If any suit or action is filed by any party to enforce this Agreement or otherwise with respect to the subject matter of this Agreement, the prevailing party shall be entitled to recover reasonable attorney fees incurred in preparation or in prosecution or defense of such suit or action as fixed by the trial court and, if any appeal is taken from the decision of the trial court, reasonable attorney fees as fixed by the appellate court.</p>
                <p>16.4 Binding Effect; No Assignment by Client; Permissible Assignment by Company. This Agreement shall be binding upon and inure to the benefit of each party’s respective successors and lawful assigns; provided, however, that user (including without limitation the Traveler) may not assign this Agreement, in whole or in part, without the prior written consent of the Company (which it may or may not grant in its discretion). Any purported assignment in violation of this Section 21.4 shall be void. The Company shall have the right to assign this Agreement, or any part of it, in its sole discretion to any party, and all covenants and agreements hereunder shall inure to the benefit of and be enforceable by such successors and assigns.</p>
                <p>16.5 Subcontractors. Host shall not delegate or subcontract any of its obligations under these Terms without the Company’s prior written approval. Any subcontracting in violation of this Section shall be null and void. The Company shall have the right to approve or not approve the use of a proposed subcontractor in its sole discretion. A subcontractor approved in writing by the Company (“Approved Subcontractor”) shall be bound by all of these Terms, and shall execute these Terms as required by the Company. Host and Approved Subcontractor shall be jointly and severally responsible for Host’s and Approved Subcontractor’s performance under these Terms. Host and Approved Subcontractor agree and acknowledge that all Trip Payments will be paid by the Company only to Host. Host is exclusively responsible for all payments and fees due to Approved Subcontractor pursuant to a separate agreement between Host and Subcontractor.</p>
                       
                <h4>CONTACT INFORMATION.</h4>
                <p>If you have questions or comments about this Agreement or the Company Websites, Company Programs or Services, please write, phone or email us via the contact information below:</p>
                <p>Go-Peaks, Inc.<br>
                4148 Molino, Irvine, CA 92618<br>
                +1 (415) 999-8044<br>
                Email us: <a href="mailto:o@getadventures.co">o@getadventures.co</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';

    import BaseButton from '../components/common/BaseButton.vue';
    import Review from '../components/common/Review.vue'

    import moment from '@/plugins/moment';
    import trips from '../store/modules/trips';
    moment.locale('ru');

    export default {
        name: 'BookingTermsHost',
        metaInfo() {
            return {
                title: this.lang === 'ru' ? 'Правила использования' : 'Host Booking Terms'
            }
        },
        components: {
            BaseButton,
            Review,
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            langUrl: '/about'
        }),
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('reviews/fetch', { limit: 3, page: 1 });
            this.$root.$emit('preloaderHide');
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
        },
        methods: {
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
        },
    };
</script>

<style lang="scss">
.terms {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    // display: flex;
    // flex-direction: column;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/about/About_1.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            // background: #543919;
            background: #000;
            opacity: 0.5;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 40px;
            @media all and (max-width: 768px) {
                height: 356px;
            }
            &__text {
                font-weight: bold;
                font-size: 56px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
    &__text {
        max-width: 1400px;
        margin: 0 auto;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        &__container {
            display: flex;
        }
        > h2 {
            font-size: 30px;
            margin-bottom: 40px;
        }
        > h3 {
            font-size: 20px;
            font-weight: bold;
            align-self: center;
            &:last-of-type {
                margin-bottom: 40px;
            }
        }
        > h4 {
            font-size: 16px;
        }
        > h5 {
            font-size: 14px;
            font-style: italic;
        }
        > h6 {
            font-size: 14px;
            text-decoration: underline;
        }
        > p {
            font-size: 14px;
            margin-bottom: 20px;
            > span {
                font-weight: bold;
            }
        }
        > table {
            max-width: fit-content;
            align-self: center;
            border: 1px #000 solid;
            margin-bottom: 20px;
            td {
                padding: 0 10px;
                border: 1px #000 solid;
            }
        }
    }
}

</style>